/**
 * @generated SignedSource<<457d9f4757455e3331b1792c61dce3d3>>
 * @relayHash 31f72007a0713b3bc1a98502fe31c7c4
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 640210055d4b710d8f7f5963fb10cd7990f93143c4812c52ab24060e17d333cf

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventAttendeeStatus = "ATTENDING_IN_PERSON" | "ATTENDING_VIRTUAL" | "NOT_ATTENDING" | "WAITING_IN_PERSON" | "WAITING_VIRTUAL" | "%future added value";
export type AttendEventInput = {
  clientMutationId?: string | null | undefined;
  eventId?: String | null | undefined;
  status?: EventAttendeeStatus | null | undefined;
};
export type EventAttendButtonSingleAttendMutation$variables = {
  input: AttendEventInput;
};
export type EventAttendButtonSingleAttendMutation$data = {
  readonly attendEvent: {
    readonly event: {
      readonly " $fragmentSpreads": FragmentRefs<"EventItemNew_event" | "EventScreenAttendeesDialogContent_event" | "EventScreenNewDesktop_event" | "EventScreenNewMobile_event">;
    } | null | undefined;
  } | null | undefined;
};
export type EventAttendButtonSingleAttendMutation = {
  response: EventAttendButtonSingleAttendMutation$data;
  variables: EventAttendButtonSingleAttendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prettyUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentType",
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slugId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "primaryPhoto",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "GuildMembership",
  "kind": "LinkedField",
  "name": "myMembership",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "membershipLevel",
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMe",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailMd5",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "primaryPhoto",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v6/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "kind": "InlineFragment",
  "selections": [
    (v5/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v18 = {
  "kind": "Literal",
  "name": "filter",
  "value": {
    "status": {
      "in": [
        "ATTENDING_IN_PERSON",
        "ATTENDING_VIRTUAL",
        "WAITING_IN_PERSON",
        "WAITING_VIRTUAL"
      ]
    }
  }
},
v19 = [
  (v18/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 8
  }
],
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v21 = [
  (v12/*: any*/),
  (v13/*: any*/),
  (v4/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v5/*: any*/)
],
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v23 = [
  (v20/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "EventAttendeesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventAttendee",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v22/*: any*/)
    ],
    "storageKey": null
  }
],
v24 = [
  (v20/*: any*/)
],
v25 = {
  "kind": "Literal",
  "name": "filter",
  "value": {
    "status": {
      "in": [
        "WAITING_IN_PERSON",
        "WAITING_VIRTUAL"
      ]
    }
  }
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "presenterFirstName",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "presenterLastName",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "presenter",
  "plural": false,
  "selections": (v21/*: any*/),
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stateProvinceRegionCode",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zipPostalCode",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "location",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "geojson",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v37 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v38 = [
  {
    "kind": "Literal",
    "name": "condition",
    "value": {
      "status": "ATTENDING_IN_PERSON"
    }
  },
  (v37/*: any*/)
],
v39 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EventAttendeesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      (v22/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "EventAttendee",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v4/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v20/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v40 = [
  "condition"
],
v41 = [
  (v25/*: any*/),
  (v37/*: any*/)
],
v42 = [
  {
    "kind": "Literal",
    "name": "condition",
    "value": {
      "status": "ATTENDING_VIRTUAL"
    }
  },
  (v37/*: any*/)
],
v43 = [
  {
    "kind": "Literal",
    "name": "condition",
    "value": {
      "status": "NOT_ATTENDING"
    }
  },
  (v37/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventAttendButtonSingleAttendMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AttendEventPayload",
        "kind": "LinkedField",
        "name": "attendEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EventItemNew_event"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EventScreenNewMobile_event"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EventScreenNewDesktop_event"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EventScreenAttendeesDialogContent_event"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventAttendButtonSingleAttendMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AttendEventPayload",
        "kind": "LinkedField",
        "name": "attendEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startAt",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasExternalUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasVenue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visibility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "coverPhoto",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": "eventOwner",
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "owner",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v4/*: any*/)
                    ],
                    "type": "Guild",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v9/*: any*/),
                      (v14/*: any*/),
                      (v4/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "type": "User",
                    "abstractKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "attendeesByRelevance",
                "args": (v19/*: any*/),
                "concreteType": "EventAttendeesConnection",
                "kind": "LinkedField",
                "name": "eventAttendeesByRelevance",
                "plural": false,
                "selections": (v23/*: any*/),
                "storageKey": "eventAttendeesByRelevance(filter:{\"status\":{\"in\":[\"ATTENDING_IN_PERSON\",\"ATTENDING_VIRTUAL\",\"WAITING_IN_PERSON\",\"WAITING_VIRTUAL\"]}},first:8)"
              },
              {
                "alias": "attendingCount",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "status": {
                        "in": [
                          "ATTENDING_IN_PERSON",
                          "ATTENDING_VIRTUAL"
                        ]
                      }
                    }
                  }
                ],
                "concreteType": "EventAttendeesConnection",
                "kind": "LinkedField",
                "name": "eventAttendees",
                "plural": false,
                "selections": (v24/*: any*/),
                "storageKey": "eventAttendees(filter:{\"status\":{\"in\":[\"ATTENDING_IN_PERSON\",\"ATTENDING_VIRTUAL\"]}})"
              },
              {
                "alias": "waitingCount",
                "args": [
                  (v25/*: any*/)
                ],
                "concreteType": "EventAttendeesConnection",
                "kind": "LinkedField",
                "name": "eventAttendees",
                "plural": false,
                "selections": (v24/*: any*/),
                "storageKey": "eventAttendees(filter:{\"status\":{\"in\":[\"WAITING_IN_PERSON\",\"WAITING_VIRTUAL\"]}})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EventTicketingTiersConnection",
                "kind": "LinkedField",
                "name": "eventTicketingTiers",
                "plural": false,
                "selections": (v24/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EventAttendee",
                "kind": "LinkedField",
                "name": "myAttendance",
                "plural": false,
                "selections": [
                  (v26/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventAttendeeTicketPurchase",
                    "kind": "LinkedField",
                    "name": "ticketPurchase",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stripePaymentIntentStatus",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v26/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "owner",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v9/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sponsorshipDisplay",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v10/*: any*/),
                      (v4/*: any*/)
                    ],
                    "type": "Guild",
                    "abstractKey": null
                  },
                  (v17/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v14/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v9/*: any*/),
                      (v4/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "type": "User",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": "eventItemPresentations",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": [
                      "CREATED_AT_ASC",
                      "ID_ASC"
                    ]
                  }
                ],
                "concreteType": "PresentationsConnection",
                "kind": "LinkedField",
                "name": "presentations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PresentationsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Presentation",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v27/*: any*/),
                          (v4/*: any*/),
                          (v28/*: any*/),
                          (v29/*: any*/),
                          (v30/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v22/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "presentations(orderBy:[\"CREATED_AT_ASC\",\"ID_ASC\"])"
              },
              (v31/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Venue",
                "kind": "LinkedField",
                "name": "seoVenue",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "address",
                    "plural": false,
                    "selections": [
                      (v32/*: any*/),
                      (v33/*: any*/),
                      (v34/*: any*/),
                      (v35/*: any*/),
                      (v5/*: any*/),
                      (v36/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeZone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Venue",
                "kind": "LinkedField",
                "name": "venue",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "entryInstructions",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "address",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "addressLine1",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "addressLine2",
                        "storageKey": null
                      },
                      (v32/*: any*/),
                      (v33/*: any*/),
                      (v34/*: any*/),
                      (v35/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "formattedAddress",
                        "storageKey": null
                      },
                      (v36/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PresentationsConnection",
                "kind": "LinkedField",
                "name": "presentations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PresentationsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Presentation",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/),
                          (v27/*: any*/),
                          (v31/*: any*/),
                          (v28/*: any*/),
                          (v29/*: any*/),
                          (v30/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v22/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v19/*: any*/),
                "concreteType": "EventAttendeesConnection",
                "kind": "LinkedField",
                "name": "eventAttendeesByRelevance",
                "plural": false,
                "selections": (v23/*: any*/),
                "storageKey": "eventAttendeesByRelevance(filter:{\"status\":{\"in\":[\"ATTENDING_IN_PERSON\",\"ATTENDING_VIRTUAL\",\"WAITING_IN_PERSON\",\"WAITING_VIRTUAL\"]}},first:8)"
              },
              {
                "alias": null,
                "args": [
                  (v18/*: any*/)
                ],
                "concreteType": "EventAttendeesConnection",
                "kind": "LinkedField",
                "name": "eventAttendees",
                "plural": false,
                "selections": (v24/*: any*/),
                "storageKey": "eventAttendees(filter:{\"status\":{\"in\":[\"ATTENDING_IN_PERSON\",\"ATTENDING_VIRTUAL\",\"WAITING_IN_PERSON\",\"WAITING_VIRTUAL\"]}})"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attendeeLimit",
                "storageKey": null
              },
              {
                "alias": "inPersonAttendees",
                "args": (v38/*: any*/),
                "concreteType": "EventAttendeesConnection",
                "kind": "LinkedField",
                "name": "eventAttendeesByRelevance",
                "plural": false,
                "selections": (v39/*: any*/),
                "storageKey": "eventAttendeesByRelevance(condition:{\"status\":\"ATTENDING_IN_PERSON\"},first:10)"
              },
              {
                "alias": "inPersonAttendees",
                "args": (v38/*: any*/),
                "filters": (v40/*: any*/),
                "handle": "connection",
                "key": "EventScreenAttendeesDialogInPersonAttendees_event_inPersonAttendees",
                "kind": "LinkedHandle",
                "name": "eventAttendeesByRelevance"
              },
              {
                "alias": "waitingAttendees",
                "args": (v41/*: any*/),
                "concreteType": "EventAttendeesConnection",
                "kind": "LinkedField",
                "name": "eventAttendeesByRelevance",
                "plural": false,
                "selections": (v39/*: any*/),
                "storageKey": "eventAttendeesByRelevance(filter:{\"status\":{\"in\":[\"WAITING_IN_PERSON\",\"WAITING_VIRTUAL\"]}},first:10)"
              },
              {
                "alias": "waitingAttendees",
                "args": (v41/*: any*/),
                "filters": [
                  "filter"
                ],
                "handle": "connection",
                "key": "EventScreenAttendeesDialogWaitingAttendees_event_waitingAttendees",
                "kind": "LinkedHandle",
                "name": "eventAttendeesByRelevance"
              },
              {
                "alias": "onlineAttendees",
                "args": (v42/*: any*/),
                "concreteType": "EventAttendeesConnection",
                "kind": "LinkedField",
                "name": "eventAttendeesByRelevance",
                "plural": false,
                "selections": (v39/*: any*/),
                "storageKey": "eventAttendeesByRelevance(condition:{\"status\":\"ATTENDING_VIRTUAL\"},first:10)"
              },
              {
                "alias": "onlineAttendees",
                "args": (v42/*: any*/),
                "filters": (v40/*: any*/),
                "handle": "connection",
                "key": "EventScreenAttendeesDialogOnlineAttendees_event_onlineAttendees",
                "kind": "LinkedHandle",
                "name": "eventAttendeesByRelevance"
              },
              {
                "alias": "notAttending",
                "args": (v43/*: any*/),
                "concreteType": "EventAttendeesConnection",
                "kind": "LinkedField",
                "name": "eventAttendeesByRelevance",
                "plural": false,
                "selections": (v39/*: any*/),
                "storageKey": "eventAttendeesByRelevance(condition:{\"status\":\"NOT_ATTENDING\"},first:10)"
              },
              {
                "alias": "notAttending",
                "args": (v43/*: any*/),
                "filters": (v40/*: any*/),
                "handle": "connection",
                "key": "EventScreenAttendeesDialogNotAttending_event_notAttending",
                "kind": "LinkedHandle",
                "name": "eventAttendeesByRelevance"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "640210055d4b710d8f7f5963fb10cd7990f93143c4812c52ab24060e17d333cf",
    "metadata": {},
    "name": "EventAttendButtonSingleAttendMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1fff7389a82aebb9a7cbb0299f472af1";

export default node;
